import React, {useEffect} from "react";
import {get as lodashGet} from "lodash";
import {Form} from "react-final-form";
import {Button} from "../../../../components/button/button";
import {
	MenuItem,
} from '@material-ui/core';
import {globalStyles} from "../../../../css/global.styles";
import {Select} from "../../../../components/select/select";
import {useHistory} from "react-router-dom";
import {CardWrapper} from "../../../../components/cardWrapper/cardWrapper.component";
import {Locations} from "../../../../constants/locations";
import {IManageReceptionsFormValues} from "../../interfaces/forms/manageReceptions";
import {Message} from "../../../../components/message";
import {Header} from "../../../../components/header/header.component";
import {Container} from "../../../../components/container/container.component";
import {FormFieldWrapper} from "../../../../components/formFieldWrapper/formFieldWrapper.component";
import {
	getUniqueRegistrationNumbers,
	getCardsByRegistrationNumber,
	getCardById,
	getInitialRegistrationNumber, isCustomRegistrationNumberEnabled, getRegistrationNumberFromCookie
} from "../../../../helpers/cards";
import Cookies from "js-cookie";
import {CardMenuItemContent} from "../../../../components/cardMenuItemContent/cardMenuItemContent.component";
import {CardDetailsReceiver} from "./cardDetailsReceiver.container";

export function ManageReceptionsComponent(props) {
	const {confirmationGeneratedCards, fetchConfirmationGeneratedCards, updateFormData, exchangedData, apiType} = props;
	const globalClasses = globalStyles({});
	let history = useHistory();

	useEffect(() => {
		fetchConfirmationGeneratedCards(apiType);
	}, [apiType]);

	const handleValidation = (values: IManageReceptionsFormValues) => {
		const errors: any = {};

		if (!values.vehicleRegNumber) {
			errors.vehicleRegNumber = "To pole jest wymagane";
		}

		if (!values.cardId) {
			errors.cardId = "To pole jest wymagane";
		}

		return errors;
	};

	if (!confirmationGeneratedCards) {
		return (
			<Container>
				<Header>Zarządzaj odbiorami</Header>
				<Message>Ładowanie wyników...</Message>
			</Container>
		);
	} else if (!confirmationGeneratedCards.length) {
		return (
			<Container>
				<Header>Zarządzaj odbiorami</Header>
				<Message>Aktualnie nie ma żadnych transportów do wybrania.</Message>
			</Container>
		);
	} else {
		const uniqueRegistrationNumbers = getUniqueRegistrationNumbers(confirmationGeneratedCards);
		let initialRegistrationNumber = getInitialRegistrationNumber(
			getRegistrationNumberFromCookie(),
			uniqueRegistrationNumbers,
		);

		let initialCardId = "";

		if (lodashGet(exchangedData, "type") === "goBack") {
			initialRegistrationNumber = lodashGet(exchangedData, "formValues.vehicleRegNumber");
			initialCardId = lodashGet(exchangedData, "formValues.cardId");
		}

		const initialValues: IManageReceptionsFormValues = {
			vehicleRegNumber: initialRegistrationNumber,
			cardId: initialCardId,
		};

		const handleVehicleNumberChange = (value) => {
			if (!isCustomRegistrationNumberEnabled()) {
				Cookies.set("mbdo_vehicleRegNumber", value);
			}
		};

		const onSubmit = async (values: IManageReceptionsFormValues) => {
			// do nothing
		};

		const handleReject = (form) => {
			updateFormData({
				from: Locations.MANAGE_RECEPTIONS,
				formValues: form.getState().values,
			});
			history.push(Locations.REJECT_RECEPTION);
		};

		const handleConfirm = (form) => {
			updateFormData({
				from: Locations.MANAGE_RECEPTIONS,
				formValues: form.getState().values,
			});
			history.push(Locations.CONFIRM_RECEPTION);
		};

		const getSelectedCardComponent = (selectedCard, form, values) => {
			if (!selectedCard) {
				return null;
			}

			const isButtonDisabled = Boolean(Object.keys(handleValidation(values)).length);

			return (
				<CardWrapper type="reciever">
					<CardDetailsReceiver
						card={selectedCard}
						color="reciever"
					/>

					<FormFieldWrapper alignContent="center">
						<Button onClick={() => {handleReject(form);}} className={globalClasses.centerButton} variant="contained" color="primary" disabled={isButtonDisabled}>
							Odrzuć
						</Button>
						<Button onClick={() => {handleConfirm(form);}} className={globalClasses.centerButton} variant="contained" color="primary" disabled={isButtonDisabled}>
							Potwierdz
						</Button>
					</FormFieldWrapper>
				</CardWrapper>
			);
		};

		return (
			<Container>
				<Header>Zarządzaj odbiorami</Header>
				<Form
					validate={handleValidation}
					onSubmit={onSubmit}
					initialValues={initialValues}
					render={({ handleSubmit, form, submitting, pristine, values }) => {
						const selectedCard = getCardById(confirmationGeneratedCards, values.cardId);

						return (
							<>
								<form onSubmit={handleSubmit}>
									<FormFieldWrapper>
										<Select
											required={true}
											name="vehicleRegNumber"
											label="Nr. rejestracyjny pojazdu"
											onChange={(value) => {
												form.change("cardId", "");
												handleVehicleNumberChange(value);
											}}
										>
											{uniqueRegistrationNumbers.map((regNumber) => {
												return (<MenuItem key={regNumber} value={regNumber}>{regNumber}</MenuItem>);
											})}
										</Select>
									</FormFieldWrapper>
									<FormFieldWrapper>
										<Select
											required={true}
											name="cardId"
											label="Karta przekazania odpadów"
										>
											{getCardsByRegistrationNumber(confirmationGeneratedCards, values.vehicleRegNumber).map((card) => {
												return (
													<MenuItem key={card.kpoId || card.kpokId} value={card.kpoId || card.kpokId}>
														<CardMenuItemContent apiType={apiType} card={card} />
													</MenuItem>
												);
											})}
										</Select>
									</FormFieldWrapper>
									{getSelectedCardComponent(selectedCard, form, values)}
								</form>
							</>
						);
					}}
				/>
			</Container>
		);
	}
}
